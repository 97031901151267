[data-theme="light"] {
    --opacity-bg: 0.9;
    --bg-icon: "#ffffff";
    --bg-button: '#004451';
    --shadow-login: 0px 0px 15px 5px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

[data-theme="dark"] {
    --opacity-bg: 0.4;
    --bg-icon: "#1b1b29";
    --bg-button: '#0B7488';
    --shadow-login: 'none'
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
    -webkit-text-fill-color: var(--kt-input-color);
    -webkit-box-shadow: 0 0 0px 40rem var(--kt-input-bg) inset !important;
    transition: background-color 5000s ease-in-out 0s;
}
.content_login{
    background-color: var(--kt-content-bg-color);
}
.no-show{
    display: none;
}
.login_form {
    border-radius: 10px;
    background-color: var(--kt-content-bg-color);
    box-shadow: var(--shadow-login)
}

.button_login {
    background-color: var(--bg-button);
}

.content_video{
    z-index: 100;
    background-color: var(--kt-content-bg-color);
}
.content_video .video {
    object-fit: cover;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
  }

.efect-fade{
    animation: fadeIn 3s ease;
    -moz-animation: fadeIn 3s ease; /* Firefox */
    -webkit-animation: fadeIn 3s ease; /* Safari and Chrome */
    -o-animation: fadeIn 3s ease; /* Opera */
}
@keyframes fadeIn {
    100% {
        opacity:0;
        filter: blur(20px);
    }
}

@-moz-keyframes fadeIn { /* Firefox */
    100% {
        opacity:0;
        filter: blur(20px);
    }
}
@-webkit-keyframes fadeIn { /* Safari and Chrome */
    100% {
        opacity:0;
        filter: blur(20px);
    }
}
@-o-keyframes fadeIn { /* Opera */
    100% {
        opacity:0;
        filter: blur(20px);
    }
}​

.login_aside {
    position: relative;
}

/* @media screen and (min-width: 992px) {
    .login_aside:before {
        content: ' ';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: var(--opacity-bg);
        background-image: url('../../Assets/fondo_cca.webp');
        background-repeat: no-repeat;
        background-position: 50% 0;
        background-size: cover;
    }
} */

.icon_pass {
    color: var(--kt-input-group-addon-color);
    background-color: var(--bg-icon);
}

/* .css-1fdsijx-ValueContainer {
    padding: 0.5rem 0.8rem !important;
}

.css-b62m3t-container {
    color: var(--kt-input-color);
    background-color: var(--kt-input-bg);
    border: 1px solid var(--kt-input-border-color);
    border-radius: 0.625rem;
}

.css-hudecg-control {
    background-color: var(--kt-input-bg);
    border: 1px solid var(--kt-input-border-color);
    border-radius: 0.625rem;
}

.css-fcslos-control,
.css-fcslos-control:hover {
    border: none !important;
} */