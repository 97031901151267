@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400&display=swap');
@font-face {
    font-family: "BrandingSF-Medium";
    /*Can be any text*/
    src: local("BrandingSF-Medium"), url("./fonts/BrandingSF-Medium.woff") format("woff");
}

@font-face {
    font-family: "BrandingSF-Black";
    /*Can be any text*/
    src: local("BrandingSF-Black"), url("./fonts/BrandingSF-Black.woff") format("woff");
}

@font-face {
    font-family: "BrandingSF-BlackItalic";
    /*Can be any text*/
    src: local("BrandingSF-BlackItalic"), url("./fonts/BrandingSF-BlackItalic.woff") format("woff");
}

@font-face {
    font-family: "BrandingSF-Bold";
    /*Can be any text*/
    src: local("BrandingSF-Bold"), url("./fonts/BrandingSF-Bold.woff") format("woff");
}

@font-face {
    font-family: "BrandingSF-BoldItalic";
    /*Can be any text*/
    src: local("BrandingSF-BoldItalic"), url("./fonts/BrandingSF-BoldItalic.woff") format("woff");
}

@font-face {
    font-family: "BrandingSF-SemiBold";
    /*Can be any text*/
    src: local("BrandingSF-SemiBold"), url("./fonts/BrandingSF-SemiBold.woff") format("woff");
}

@font-face {
    font-family: "BrandingSF-SemiLight";
    /*Can be any text*/
    src: local("BrandingSF-SemiLight"), url("./fonts/BrandingSF-SemiLight.woff") format("woff");
}

[data-theme="light"] {
    --content-bg-scann: #f9f9f9;
}

[data-theme="dark"] {
    --content-bg-scann: #161616;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
    -webkit-text-fill-color: var(--kt-input-color);
    -webkit-box-shadow: 0 0 0px 40rem var(--kt-input-bg) inset !important;
    transition: background-color 5000s ease-in-out 0s;
}

* {
    margin: 0;
    box-sizing: 0;
}

body {
    font-family: "BrandingSF-SemiLight", sans-serif
}


/* .card_table{
  border: 2px solid rgba(168, 167, 167, 0.609);
} */

.over_loading {
    opacity: .8 !important;
    /* background-color: #f5f5f539 !important; */
}

.css-l0diq4-control {
    border-radius: 6px !important;
}

@media screen and (min-width: 992px) {
    .aside-menu {
        margin-top: 80px !important;
    }
}

.content_scann {
    background-color: var(--content-bg-scann);
}

@media screen and (min-width: 992px) {
    .content_scann {
        padding: 30px 0;
        border-radius: 1.5rem;
    }
}

.select-input .css-1x1v73v-control:hover {
    border-color: transparent;
    box-shadow: none;
    border-radius: 0.625rem;
}

.css-hudecg-control {
    border-style: none !important;
}

.css-fcslos-control:hover {
    border-color: #1e1e2d !important;
    border-style: hidden !important;
    box-shadow: none !important
}

.css-1dimb5e-singleValue {
    color: #92929f !important;
}

textarea {
    resize: none;
}

.content_card_report {
    width: 100%;
    /* max-width: calc(100vw - 100px); */
    max-width: 2200px;
    overflow-y: scroll;
    --kt-scrollbar-size: 8px;
}

.row_content {
    width: 2200px;
}

.date_title {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}

.header_title {
    height: 70px;
    /* padding: 1em; */
}

.second_row {
    height: 60px;
}

.three_row {
    height: 70px;
}