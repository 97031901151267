.tss-1cdcmys-MUIDataTable-responsiveBase {
    min-height: 25rem;
}

.muiDataTable {
    overflow: visible; /* O ajusta según tu estructura específica */
}

tbody.MuiTableBody-root tr td {
    padding: 12px !important;
}