:root {
  --color: red;
}
.ck.ck-toolbar{
  background-color: var(--kt-content-bg-color);
  
}
.ck-reset_all :not(.ck-reset_all-excluded *), .ck.ck-reset_all{
  color: var(--kt-text-dark) !important;
}
.ck-editor {
  background-color: var(--kt-content-bg-color);
  color: var(--kt-text-dark) !important;
}
.ck.ck-editor__main>.ck-editor__editable{
  background-color: var(--kt-content-bg-color);
}
.ck-content.ck-focused{
  border-color: var(--ck-color-base-border) !important;
}